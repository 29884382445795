import React from 'react';

const Loading = () => (
  <div className="bg-gray-300 absolute top-0 left-0 right-0 items-center flex justify-center bottom-0">
    <div className="blob-effect">
      <span />
      <span />
      <span />
    </div>
  </div>
);

export default Loading;
