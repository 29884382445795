import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu, ExcelExport,
  Filter, GridComponent,
  Inject, Page, PdfExport,
  Resize, Search,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { headerGridIntervention, itemDashboard } from './constante';
import { getAllIntervention } from '../../Services/Customers';

const InterventionScreen = () => {
  const { currentColor } = useStateContext();
  const [listPrestataire, setListPrestataire] = React.useState([]);
  const [tabPreview, setTabPreview] = React.useState([]);
  const toolbarOptions = ['Search'];
  const getAllInterventionFunc = () => {
    getAllIntervention().then((r) => {
      // eslint-disable-next-line no-underscore-dangle
      setListPrestataire(r?._description);
      // eslint-disable-next-line no-shadow,no-underscore-dangle
      const groups = r?._description.reduce((groups, item) => {
        const group = (groups[item.customerCommune] || []);
        group.push(item);
        // eslint-disable-next-line no-param-reassign
        groups[item.customerCommune] = group;
        return groups;
      }, {});
      const Global = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(groups)) {
        Global.push({
          commune: key,
          number: value?.length,
        });
      }
      setTabPreview(Global);
    });
  };

  React.useEffect(() => {
    getAllInterventionFunc();
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 rounded-3xl">
      <Header category="Page" title="Consultation des interventions" />
      <h1 className="font-bold text-xl mb-4 uppercase mt-3" style={{ color: currentColor }}>Liste des interventions par communes</h1>
      <div className="flex m-3 flex-wrap gap-1 items-center">
        {
            tabPreview?.map((item) => (
              <div key={itemDashboard[3].title} className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
                <button
                  type="button"
                  style={{ color: itemDashboard[3].iconColor, backgroundColor: itemDashboard[3].iconBg }}
                  className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
                >
                  {itemDashboard[3].icon}
                </button>
                <p className="mt-3">
                  <span className="text-lg font-semibold">{item?.number}</span>

                </p>
                <p className="text-sm text-gray-400  mt-1">{item.commune}</p>
              </div>
            ))
          }

      </div>
      <h1 className="font-bold text-xl mb-4 uppercase mt-3" style={{ color: currentColor }}>Liste des interventions</h1>
      <div className="">
        <GridComponent
          id="gridcomp"
          dataSource={listPrestataire}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {headerGridIntervention.map((item, index) => <ColumnDirective editTemplate={false} key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, Search, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>

    </div>
  );
};

export default InterventionScreen;
