import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu, ExcelExport,
  Filter, GridComponent,
  Inject, Page, PdfExport,
  Resize, Search,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { headerGridComponent } from './constante';
import { getCommune, PostCommune } from '../../Services/Customers';

const DepatmentScreen = () => {
  const { currentColor } = useStateContext();
  const [listCommune, setListCommune] = React.useState([]);
  const [DName, setDName] = React.useState('');
  const [CommuneField, setCommuneField] = React.useState('');
  const editing = { allowDeleting: true, allowEditing: true };
  const toolbarOptions = ['Search'];
  const getCommuneFunc = () => {
    getCommune().then((r) => {
      const T = [];
      // eslint-disable-next-line no-unsafe-optional-chaining,no-underscore-dangle,no-restricted-syntax
      for (const ins of r?._description) {
        T.push({
          name: ins.libelle,
        });
      }
      setListCommune(T);
    });
  };
  const postCommune = () => {
    if (CommuneField !== '') {
      PostCommune({
        libelle: CommuneField,
        // eslint-disable-next-line no-unused-vars
      }).then((r) => {
        setDName('-');
        getCommuneFunc();
      });
    } else {
      alert('Vous devez remplir le champ');
    }
  };
  React.useEffect(() => {
    getCommuneFunc();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Consultation des communes" />
      <h1 className="font-bold text-xl mb-4 uppercase" style={{ color: currentColor }}>Ajouter une commune</h1>
      {
        DName !== ''
          ? (
            <div className="p-5 bg-green-300 mb-2">
              <p className="font-bold text-xl text-center text-white">Opération succès !!!</p>
              <p className="text-center text-white">Vous avez ajouter une nouvelle commune</p>

            </div>
          ) : null
      }

      <div className="mb-5">
        <input
          onChange={(e) => {
            setCommuneField(e.target.value);
          }}
          className="h-14 w-52 mt-1 bg-gray-100 text-base rounded-xl p-2"
          type="text"
          placeholder="Nom de la commune"
        />
        <button
          type="button"
          onClick={() => postCommune()}
          style={{ backgroundColor: currentColor }}
          className="relative h-14 text-base rounded-xl p-3 ml-2 text-white"
        >
          Valider
        </button>
      </div>
      <h1 className="font-bold text-xl mb-4 uppercase" style={{ color: currentColor }}>Listes des communes </h1>
      <div className="">
        <GridComponent
          id="gridcomp"
          dataSource={listCommune}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {headerGridComponent.map((item, index) => <ColumnDirective editTemplate={false} key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, Search, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>

    </div>
  );
};

export default DepatmentScreen;
