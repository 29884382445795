import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import { Provider } from 'react-redux';
import NavigationRooter from './Navigation';
import { ContextProvider } from './contexts/ContextProvider';
import { store } from './Store';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextProvider>
        <NavigationRooter />
      </ContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
