import axios from 'axios';
import { API_URL } from '../Config';

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 12000,
});

instance.interceptors.response.use(
  (response) => {
    // eslint-disable-next-line no-underscore-dangle
    if (response.data?._erreur === -1) {
      console.debug('Votre session a expiré, veuillez vous réconnecter');
      return {
        message: 'Votre session a expiré, veuillez vous réconnecter',
        data: {},
        status: -101,
      };
    }
    return response;
  },
  ({ message, response: { data, status } }) => ({ message, data, status }),
);

export default instance;
