import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Login } from '../Store/Auth';
import { useStateContext } from '../contexts/ContextProvider';
import { PrimaryColor } from '../Config';
import '../assets/LoginScreen.css';
import LoadingComponent from '../components/Loading';
import LoginService from '../Services/Auth/LoginService';

const LoginScreen = () => {
  const { loading, setLoading } = useStateContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginObj, setLoginObj] = React.useState({
    uid: '',
    password: '',
  });
  const checkIfAuth = () => {
    const i = localStorage.getItem('auth');
    console.log(JSON.parse(i));
    if (i?.length > 0) {
      navigate('/Main');
    }
  };
  React.useEffect(async () => {
    const data = await localStorage.getItem('auth');
    if (JSON.parse(data) !== null) {
      navigate('/Main');
    }
  });

  const loginFunc = async () => {
    if ((loginObj?.password === '' && loginObj.uid === '')) {
      alert('Les champs sont pas remplir !!!');
    } else {
      setLoading(true);
      LoginService(loginObj).then((r) => {
        setTimeout(() => {
          setLoading(false);
          // eslint-disable-next-line no-underscore-dangle
          if (r?._status === -1) {
            alert('Vos information sont incorrect !!!');
            return;
          }
          // eslint-disable-next-line no-underscore-dangle
          if (r?._description?.length > 0) {
            // eslint-disable-next-line no-underscore-dangle
            dispatch(Login(r?._description));
            // eslint-disable-next-line no-underscore-dangle
            localStorage.setItem('auth', JSON.stringify(r?._description));
            navigate('/Main');
          } else {
            alert('Vos information sont incorrect !!!');
          }
        }, 2000);
      });
    }
  };
  React.useEffect(() => {
    checkIfAuth();
  }, []);
  return loading ? <LoadingComponent /> : (
    <div className="flex w-full items-center justify-center bg" style={{ height: '100vh', backgroundColor: PrimaryColor }}>
      <div className="p-10 bg-white rounded-xl flex justify-center items-center" style={{ width: '30%' }}>
        <div>
          <h1 className="font-bold text-center text-2xl text-primaryC">Bienvenue a NAYA ADMIN</h1>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          <p className="text-center mt-5 text-gray-500">Cette partie de naya est specialement pour<br />L'administration des application</p>
          <p className="text-center mt-5 font-bold text-primaryC">Connectez-vous</p>
          <div className="rounded-xl h-12 mt-3">
            <input onChange={(text) => setLoginObj({ ...loginObj, uid: text.target.value })} placeholder="Vôtre identifiant" className="p-2 w-full h-full bg-gray-200" />
          </div>
          <div className="rounded-xl h-12 mt-3">
            <input onChange={(text) => setLoginObj({ ...loginObj, password: text.target.value })} placeholder="Vôtre mot de passe" type="password" className="p-2 w-full h-full bg-gray-200" />
          </div>
          <div onClick={loginFunc} className="h-12 mt-4 bg-primaryC flex items-center justify-center rounded-xl">
            <span className="text-white">Connexion</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
