import api from '../index';

export default async () => {
  let response;
  try {
    response = await api.get('_admin/allInterv');
  } catch (e) {
    console.debug(e);
    return {
      message: 'Erreur réseau, vérifiez votre connexion internet et réessayez.',
      data: '',
      // eslint-disable-next-line no-underscore-dangle
      status: response?.data._status,
    };
  }
  return response.data;
};
