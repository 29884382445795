import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu, ExcelExport,
  Filter, GridComponent,
  Inject, Page, PdfExport,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import { headerGridComponent } from './constante';
import { GetPrestataire } from '../../Services/Prestataire';

const WarningScreen = () => {
  const [listPrestataire, setListPrestataire] = React.useState([]);
  const editing = { allowDeleting: true, allowEditing: true };
  const toolbarOptions = ['Search'];
  const getPrestataire = () => {
    // eslint-disable-next-line no-underscore-dangle
    GetPrestataire().then((r) => setListPrestataire(r?._description));
  };
  React.useEffect(() => {
    getPrestataire();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Consultation des recharges" />
      <div className="">
        <GridComponent
          id="gridcomp"
          dataSource={listPrestataire}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {headerGridComponent.map((item, index) => <ColumnDirective editTemplate={false} key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>

    </div>
  );
};

export default WarningScreen;
