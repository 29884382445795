import {
  MdLocationCity,
  MdOutlineSupervisorAccount,
  MdSupervisedUserCircle,
} from 'react-icons/md';
import { BsBoxSeam } from 'react-icons/bs';
import React from 'react';
import { gridOrderStatus } from '../../data/dummy';
import product6 from '../../data/product6.jpg';

export const itemDashboard = [
  {
    icon: <MdOutlineSupervisorAccount />,
    amount: '39,354',
    percentage: '-4%',
    title: 'Client',
    iconColor: '#03C9D7',
    iconBg: '#E5FAFB',
    pcColor: 'red-600',
  },
  {
    icon: <MdSupervisedUserCircle />,
    amount: '39,354',
    percentage: '-4%',
    title: 'Prestataire',
    iconColor: 'white',
    iconBg: '#245ec9',
    pcColor: 'red-600',
  },
  {
    icon: <BsBoxSeam />,
    amount: '4,396',
    percentage: '+23%',
    title: 'Recharge',
    iconColor: 'rgb(255, 244, 229)',
    iconBg: 'rgb(254, 201, 15)',
    pcColor: 'green-600',
  },
  {
    icon: <MdLocationCity />,
    amount: '39,354',
    percentage: '-12%',
    title: 'Commune',
    iconColor: 'rgb(0, 194, 146)',
    iconBg: 'rgb(235, 250, 242)',
    pcColor: 'red-600',
  },
];
export const headerGridComponent = [
  {
    field: 'code',
    headerText: 'Code',
    format: 'C2',
    textAlign: 'Center',
    width: '150',
  },
  {
    field: 'montant',
    headerText: 'Montant',
    width: '120',
    textAlign: 'Center',
  },
  {
    headerText: 'Status',
    template: gridOrderStatus,
    field: 'status',
    textAlign: 'Center',
    width: '120',
  },
];
export const PrestataireData = [
  {
    number: '0555412087',
    email: 'jeanphilippesara225@gmail.com',
    wallet: 32.38,
    name: 'Fresh Tomato',
    depatment: 'USA',
    status: 'pending',
    StatusBg: '#FB9678',
    ProductImage:
    product6,
  },
];
