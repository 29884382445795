import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  item: [],
  loading: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    Login: (state, action) => {
      // eslint-disable-next-line no-param-reassign
      state.item = action.payload;
    },
    Logout: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.item = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { Login, Logout } = authSlice.actions;

export default authSlice.reducer;
