import React from 'react';
import { BsFileArrowDownFill } from 'react-icons/bs';
import { Button, Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { GetChangeDocumentVerif, getListDocumentVerifAll, GetListDocumentVerifFunc } from '../../Services/OtherServices';

const ValideVerificationScreen = () => {
  const { currentColor } = useStateContext();
  const [DocVerif, setDocVerif] = React.useState([]);
  const [DocVerifAll, setDocVerifAll] = React.useState([]);
  const [globalStep, setglobalStep] = React.useState([]);
  const getPrestataire = () => {
    GetListDocumentVerifFunc().then((r) => {
      // eslint-disable-next-line no-underscore-dangle
      setDocVerif(r?._description);
      let T = [];
      // eslint-disable-next-line no-restricted-syntax,no-unsafe-optional-chaining,no-underscore-dangle
      for (const items of r?._description) {
        T = { ...T,
          [`chekbox${items?.id}`]: '0',
        };
      }
      setglobalStep(T);
    });
  };
  const getAllData = () => {
    getListDocumentVerifAll().then((r) => {
      // eslint-disable-next-line no-underscore-dangle
      if (r?._status === 1) {
        // eslint-disable-next-line no-underscore-dangle
        setDocVerifAll(r?._description);
      }
    });
  };
  const saveData = (data) => {
    GetChangeDocumentVerif({
      isAccepted: data?.checkbox === 1,
      id: data?.id,
    }).then((r) => {
      // eslint-disable-next-line no-underscore-dangle
      if (r?._status !== 1) {
        alert('Erreur systeme !!!');
        return;
      }
      getPrestataire();
      getAllData();
    });
  };
  React.useEffect(() => {
    getPrestataire();
    getAllData();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Consultation des documents a valider" />
      <div className="flex flex-wrap lg:flex-nowrap bg-gray-200 mb-6">
        {
          DocVerif?.map((item) => (
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div className="">
                  <p className="font-bold text-gray-400">{item?.name}</p>
                  <p className="text-xl">tel: {item?.phone_tel}</p>
                </div>
                <button
                  type="button"
                  style={{ backgroundColor: currentColor }}
                  className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  <BsFileArrowDownFill />
                </button>
              </div>
              <div>
                <h1>Validation du document</h1>
                <div>
                  <span>Oui</span>
                  <input
                    type="radio"
                    name={`chekbox${item?.id}`}
                    value="active"
                    onClick={() => {
                      setglobalStep({ ...globalStep, [`chekbox${item?.id}`]: '1' });
                      console.log('active', item?.id);
                    }}
                    className="m-2"
                    style={{ backgroundColor: currentColor }}
                  />
                </div>
                <div>
                  <span className="text-red-700">Non</span>
                  <input
                    type="radio"
                    name={`chekbox${item?.id}`}
                    value="inactive"
                    className="m-2"
                    onClick={() => {
                      setglobalStep({ ...globalStep, [`chekbox${item?.id}`]: 'O' });
                      console.log('desactive', item?.id);
                    }}
                  />
                </div>
              </div>
              <div className="mt-6">
                <div onClick={() => {
                  console.log('k', globalStep);
                  saveData({
                    checkbox: parseInt(globalStep?.[`chekbox${item?.id}`], 10),
                    id: item?.id,
                  });
                }}
                >
                  <Button
                    color="white"
                    bgColor="green"
                    text="Sauvegarder"
                    borderRadius="10px"
                  />
                </div>

              </div>
              <div className="mt-4">
                <a href={`${item?.link_document}`}>
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Telecharger le document"
                    borderRadius="10px"
                  />
                </a>

              </div>
            </div>
          ))
        }

      </div>
      <Header category="" title="Consultation de tous les documents " />
      <div className="flex flex-wrap lg:flex-nowrap bg-gray-200 ">
        {
          DocVerifAll?.map((item) => (item?.isCertif !== 0 && item?.isCertif !== 1 ? (
            <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg  rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
              <div className="flex justify-between items-center">
                <div className="">
                  <p className="font-bold text-gray-400">{item?.name}</p>
                  <p className="text-xl">tel: {item?.phone_tel}</p>
                </div>
                <button
                  type="button"
                  style={{ backgroundColor: currentColor }}
                  className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
                >
                  <BsFileArrowDownFill />
                </button>
              </div>
              <div>
                <h1>Etat</h1>
                <span className={item?.isCertif !== 2 ? 'font-bold text-red-300' : 'font-bold text-green-300'}>{item?.isCertif === 2 ? 'Actif' : 'Rejetter'}</span>
              </div>
              <div className="mt-4">
                <a href={`${item?.link_document}`}>
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Telecharger le document"
                    borderRadius="10px"
                  />
                </a>

              </div>
            </div>
          ) : null))
        }

      </div>

    </div>
  );
};

export default ValideVerificationScreen;
