import React from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu, ExcelExport,
  Filter, GridComponent,
  Inject, Page, PdfExport,
  Resize, Search,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Button } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { headerGridComponent, itemDashboard } from './constante';
import { GetPrestataire } from '../../Services/Prestataire';
import { getCommune, GetCustomers, getListWallet } from '../../Services/Customers';

const DashBoardScreen = () => {
  const { currentColor } = useStateContext();
  const [listPrestataire, setListPrestataire] = React.useState([]);
  const [listWallet, setListWallet] = React.useState([]);
  const [totalSellWallet, settotalSellWallet] = React.useState('');
  const [listCustomers, setListCustomers] = React.useState([]);
  const [ListCommune, setListCommune] = React.useState([]);
  const editing = { allowDeleting: false, allowEditing: false };
  const toolbarOptions = ['Search'];

  const getCustomers = () => {
    // eslint-disable-next-line no-underscore-dangle
    GetCustomers().then((r) => setListCustomers(r?._description));
  };
  const getPrestataire = () => {
    // eslint-disable-next-line no-underscore-dangle
    GetPrestataire().then((r) => setListPrestataire(r?._description));
  };
  const getWallet = () => {
    getListWallet().then((r) => {
      const initialValue = 0;
      const total = [];
      // eslint-disable-next-line no-restricted-syntax,no-underscore-dangle,no-unsafe-optional-chaining
      for (const ins of r?._description.filter((R) => R.isUse === 0)) {
        total.push(ins.montant);
      }
      // eslint-disable-next-line no-underscore-dangle
      setListWallet(r?._description);
      settotalSellWallet(total.reduce((previousValue, currentValue) => previousValue + currentValue, initialValue));
    });
  };
  const getCommuneFunc = () => {
    getCommune().then((r) => {
      const T = [];
      // eslint-disable-next-line no-unsafe-optional-chaining,no-underscore-dangle,no-restricted-syntax
      for (const ins of r?._description) {
        T.push({
          name: ins.libelle,
        });
      }
      setListCommune(T);
    });
  };
  React.useEffect(() => {
    getPrestataire();
    getCommuneFunc();
    getCustomers();
    getWallet();
  }, []);
  return (
    <div className="mt-24">
      <div className="flex flex-wrap lg:flex-nowrap justify-center ">
        <div className="bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-44 rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center">
          <div className="flex justify-between items-center">
            <div className="">
              <p className="font-bold text-gray-400">Nombre deRecharge </p>
              <p className="font-bold text-gray-400">vendu et utiliser</p>
            </div>
            <button
              type="button"
              style={{ backgroundColor: currentColor }}
              className="text-2xl opacity-0.9 text-white hover:drop-shadow-xl rounded-full  p-4"
            >
              <BsCurrencyDollar />
            </button>
          </div>
          <div className="mt-6">
            <Button
              color="white"
              bgColor={currentColor}
              text={`${totalSellWallet}XOF`}
              borderRadius="10px"
            />
          </div>
        </div>
        <div className="flex m-3 flex-wrap justify-center gap-1 items-center">
          <div key={itemDashboard[0].title} className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: itemDashboard[0].iconColor, backgroundColor: itemDashboard[0].iconBg }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              {itemDashboard[0].icon}
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{listCustomers?.length}</span>
            </p>
            <p className="text-sm text-gray-400  mt-1">{itemDashboard[0].title}</p>
          </div>
          <div key={itemDashboard[1].title} className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: itemDashboard[1].iconColor, backgroundColor: itemDashboard[1].iconBg }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              {itemDashboard[1].icon}
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{listPrestataire?.length}</span>
            </p>
            <p className="text-sm text-gray-400  mt-1">{itemDashboard[1].title}</p>
          </div>
          <div key={itemDashboard[2].title} className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: itemDashboard[2].iconColor, backgroundColor: itemDashboard[2].iconBg }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              {itemDashboard[2].icon}
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{listWallet?.length}</span>
            </p>
            <p className="text-sm text-gray-400  mt-1">{itemDashboard[2].title}</p>
          </div>
          <div key={itemDashboard[3].title} className="bg-white h-44 dark:text-gray-200 dark:bg-secondary-dark-bg md:w-56  p-4 pt-9 rounded-2xl ">
            <button
              type="button"
              style={{ color: itemDashboard[3].iconColor, backgroundColor: itemDashboard[3].iconBg }}
              className="text-2xl opacity-0.9 rounded-full  p-4 hover:drop-shadow-xl"
            >
              {itemDashboard[3].icon}
            </button>
            <p className="mt-3">
              <span className="text-lg font-semibold">{ListCommune?.length}</span>

            </p>
            <p className="text-sm text-gray-400  mt-1">{itemDashboard[3].title}</p>
          </div>
        </div>
      </div>
      <div className="m-10">
        <h1 className="font-bold text-xl mb-4 uppercase" style={{ color: currentColor }}>Liste des prestataires</h1>
        <GridComponent
          id="gridcomp"
          dataSource={listPrestataire}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {headerGridComponent.map((item, index) => <ColumnDirective editTemplate={false} key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Search, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>

    </div>
  );
};

export default DashBoardScreen;
