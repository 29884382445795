import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import '../App.css';
import MainStack from './mainStack';
import AuthStack from './authStack';
import { useStateContext } from '../contexts/ContextProvider';

const NavigationRooter = () => {
  const { setCurrentColor, setCurrentMode, currentMode } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem('colorMode');
    const currentThemeMode = localStorage.getItem('themeMode');
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);

  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
      <BrowserRouter>
        <Routes>
          <Route path="/Auth/*" element={(<AuthStack />)} />
          <Route path="/" element={(<AuthStack />)} />
          <Route path="/Main/*" element={(<MainStack />)} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default NavigationRooter;
