import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './TestRedux/counterSlice';
import authReducer from './Auth';

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    auth: authReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
});
