import api from '../index';

export default async (data) => {
  let response;
  try {
    if (data?.isAccepted) {
      response = await api.get(`/_admin/verifValid/${data?.id}`);
      console.log('accepted');
    } else {
      response = await api.get(`/_admin/verifReject/${data?.id}`);
      console.log('rejected');
    }
  } catch (e) {
    console.debug(e);
    return {
      message: 'Erreur réseau, vérifiez votre connexion internet et réessayez.',
      data: '',
      // eslint-disable-next-line no-underscore-dangle
      status: response?.data._status,
    };
  }
  if (!response?.data) {
    return {
      message: 'Erreur réseau, vérifiez votre connexion internet et réessayez.',
      data: '',
      // eslint-disable-next-line no-underscore-dangle
      status: response?.data?._status,
    };
  }

  return response.data;
};
