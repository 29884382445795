import React from 'react';
import { Routes, Route } from 'react-router-dom';
import {
  LoginScreen,
} from '../pages';
import '../App.css';

const AuthStack = () => (
  <Routes>
    {/* dashboard  */}
    <Route path="/" element={(<LoginScreen />)} />
  </Routes>
);

export default AuthStack;
