import React from 'react';
import { MdOutlineCancel } from 'react-icons/md';

import { useNavigate } from 'react-router-dom';
import { Button } from '.';
import { useStateContext } from '../contexts/ContextProvider';

const UserProfile = () => {
  const { currentColor, setIsClicked, initialState } = useStateContext();
  const navigate = useNavigate();

  async function logout() {
    await localStorage.removeItem('auth');
    navigate('/Auth');
  }
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96">
      <div className="flex justify-between items-center">
        <Button
          icon={<MdOutlineCancel />}
          color="rgb(153, 171, 180)"
          bgHoverColor="light-gray"
          size="2xl"
          borderRadius="50%"
        />
      </div>
      <div className="mt-5">
        <button
          type="button"
          onClick={() => {
            setIsClicked(initialState);
            logout();
          }}
          style={{ backgroundColor: currentColor, color: 'white', borderRadius: '10px' }}
          className={` text-base p-3 w-full hover:drop-shadow-xl hover:bg-${currentColor}`}
        >
          Logout
        </button>
      </div>
    </div>

  );
};

export default UserProfile;
