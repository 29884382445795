import React from 'react';
import { Header } from '../../components';
import { GetPrestataire } from '../../Services/Prestataire';

const DashBoardScreen = () => {
  const getPrestataire = () => {
    GetPrestataire();
  };
  React.useEffect(() => {
    getPrestataire();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Créer une recharge" />
      <div className="">
        <h1 className="text-xl">Montant de la recharge</h1>
        <input className="h-12 ml-2 w-52 mt-1 bg-gray-100 rounded-xl p-2" type="number" placeholder="montant" />
      </div>
    </div>
  );
};

export default DashBoardScreen;
