import React from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu, ExcelExport,
  Filter, GridComponent,
  Inject, Page, PdfExport,
  Resize,
  Sort,
} from '@syncfusion/ej2-react-grids';
import { Header } from '../../components';
import { useStateContext } from '../../contexts/ContextProvider';
import { headerGridComponent } from './constante';
import { getListWallet, postCodeWallet } from '../../Services/Customers';

const DashBoardScreen = () => {
  const { currentColor } = useStateContext();
  const [RCode, setRCode] = React.useState('');
  const [RCodefield, setRCodefield] = React.useState('');
  const [listwallet, setListWallet] = React.useState([]);
  const editing = { allowDeleting: true, allowEditing: true };
  const toolbarOptions = ['Search'];
  const getWallet = () => {
    // eslint-disable-next-line no-underscore-dangle
    getListWallet().then((r) => setListWallet(r?._description));
  };
  const postCode = () => {
    if (RCodefield !== '') {
      if (RCodefield < 1000) {
        alert('Vous devez saisie une valeur superieur a 1000FR');
        return;
      }
      postCodeWallet({
        amount: RCodefield,
      }).then((r) => {
        // eslint-disable-next-line no-underscore-dangle
        setRCode(r?._code);
        getWallet();
        setTimeout(() => {
          setRCodefield('');
        }, 3000);
      });
    } else {
      alert('Vous devez remplir le champ');
    }
  };
  React.useEffect(() => {
    getWallet();
  }, []);
  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Page" title="Recharges" />
      <h1 className="font-bold text-xl mb-4 uppercase" style={{ color: currentColor }}>Créer une recharge</h1>
      {
        RCode !== ''
          ? (
            <div className="p-5 bg-green-300 mb-2">
              <p className="font-bold text-xl text-center text-white">Opération succès !!!</p>
              <p className="text-center text-white">Vous avez ajouter une nouvelle recharge<br />le code ci-dessous</p>
              <div className="flex items-center justify-center">
                <button
                  type="button"
                  style={{ backgroundColor: currentColor }}
                  className="text-center h-14 p-3 mt-3 rounded-xl text-white font-bold"
                >
                  {RCode}
                </button>
              </div>
            </div>
          ) : null
      }

      <div className="mb-5">
        <input
          onChange={(e) => {
            setRCodefield(e.target.value);
          }}
          className="h-14 w-52 mt-1 bg-gray-100 text-base rounded-xl p-2"
          type="number"
          placeholder="Montant de la recharge"
        />
        <button
          type="button"
          onClick={() => postCode()}
          style={{ backgroundColor: currentColor }}
          className="relative h-14 text-base rounded-xl p-3 ml-2 text-white"
        >
          Valider
        </button>
      </div>
      <h1 className="font-bold text-xl mb-4 uppercase" style={{ color: currentColor }}>Listes des recharges</h1>
      <div className="">
        <GridComponent
          id="gridcomp"
          dataSource={listwallet}
          allowPaging
          allowSorting
          allowExcelExport
          allowPdfExport
          editSettings={editing}
          toolbar={toolbarOptions}
        >
          <ColumnsDirective>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            {headerGridComponent.map((item, index) => <ColumnDirective editTemplate={false} key={index} {...item} />)}
          </ColumnsDirective>
          <Inject services={[Resize, Sort, ContextMenu, Filter, Page, ExcelExport, PdfExport]} />
        </GridComponent>
      </div>

    </div>
  );
};

export default DashBoardScreen;
